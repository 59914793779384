import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'
import { FlexContainer } from '../elements'
import AnimatedContent from '../components/animatedContent'

const PageDefaultTemplate = ({ className, data, theme }) => {
  const page = data.markdownRemark
  const {
    title,
    subtitle,
    date,
    description,
    authorName,
    authorPosition,
    featuredImage,
    authorFbLink,
    authorLinkedinLink,
  } = page.frontmatter

  return (
    <Layout lang="en" disableArabicLanguage={true}>
      <SEO title={title} />
      <div className={className}>
        <AnimatedContent>
          <FlexContainer
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap-reverse"
          >
            <h1 className="title">{title}</h1>
            <p className="date">{new Date(date).toDateString()}</p>
          </FlexContainer>
          <h3 className="subtitle">{subtitle}</h3>
          <h4 className="description">{description}</h4>
          <div className="line-separator"></div>
          <img className="featuredImage" src={featuredImage} />
          <FlexContainer>
            <p className="author-name">{authorName}</p>
            <img></img>
          </FlexContainer>
          <p className="author-position">{authorPosition}</p>

          <article
            className="article"
            dangerouslySetInnerHTML={{ __html: page.html }}
          />
        </AnimatedContent>
      </div>
    </Layout>
  )
}

export default styled(PageDefaultTemplate)`
  margin: 15vh 0 5vh 0;
  position: relative;
  .title {
    font-family: 'Myriadpro-bold';
    margin-bottom: 10px;
  }
  .subtitle {
    font-weight: 400;
    margin-bottom: 20px;
  }
  .description {
    font-weight: 400;
  }
  .author-name {
    font-family: 'Helvetica-MediumCond';
    letter-spacing: 2px;
    font-size: 1.4rem;
    // color: ${props => props.theme.colors.yellowMelior};
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .author-position {
    font-family: 'Helvetica-light';
    font-size: 0.9rem;
    color: #3a3b3c;
  }
  .date {
    color: #3a3b3c;
    font-size: 0.9rem;
  }
  .line-separator {
    margin: 30px 0px;
    width: 100%;
    height: 1px;
    background-color: #3a3b3c;
  }
  .article {
    margin-top: 8vh;
  }
`

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        subtitle
        date
        description
        authorName
        authorPosition
        featuredImage
        authorFbLink
        authorLinkedinLink
      }
    }
  }
`
